import React, {useState, useEffect} from 'react';
import classes from "./TextArea.module.css";

const TextArea = (props) => {

  const [text, setText] = useState(props.listAsText);

  useEffect(() => {
    setText(props.listAsText);
  }, [props.listAsText]);

  return (
    <textarea ref={props.textAreaRef} rows={props.rows} className={classes.TextArea} value={text} />
  )
};

export default TextArea;

//onChange={(e) => setText(e.target.value)}