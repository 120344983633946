import React from 'react';
import SectionTitle from '../../../UI/SectionTitle/SectionTitle';
import ViewAsText from './ViewAsText/ViewAsText';
import AddGamerAction from './AddGamerAction/AddGamerAction';
import ClearQueue from './ClearQueue/ClearQueue';

import classes from './AdminActions.module.css'

const AdminActions = (props) => {
  const {queue, setQueue} = props;

  return (
    <div className={classes.AdminActions}>
      <SectionTitle>Admin Actions</SectionTitle>
      <div className={classes.AdminButtons}>
        <AddGamerAction queue={queue} setQueue={setQueue}/>
        <ViewAsText gamers={queue}/>
        <ClearQueue setQueue={setQueue}/>
      </div>
    </div>
  )
};

export default AdminActions;