import React, {useState, useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import {Modal, Button} from 'react-bootstrap';
import {addGamer} from '../../../../../slices/queue';

import classes from './AddGamerAction.module.css';

const AddGamerAction = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [newGamer, setNewGamer] = useState("");

  const inputRef = useRef(null);

  const handleHide = () => setShow(false);
  const handleShow = () => setShow(true);

  const addToQueue = () => {
    if (newGamer) {
      dispatch(addGamer({'displayName': newGamer}));
      setNewGamer("");
    }
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      inputRef.current.focus();
    }
  }, [show]);

  const keyPressed = (event) => {
    if (event.key === "Enter") {
      addToQueue();
    }
  };

  return (
    <>
      <Button className={classes.Button} onClick={handleShow}>Add to Queue</Button>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add Gamer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input ref={inputRef} onKeyPress={keyPressed} type="text" value={newGamer} onChange={e => setNewGamer(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={addToQueue}>Add</Button>
        </Modal.Footer>
      </Modal>
    </>
  )

};

export default AddGamerAction;