import React from 'react';
import {useDispatch} from "react-redux";
import {moveGamerToEnd} from "../../../../../slices/queue";
import {Button} from "react-bootstrap";

import classes from '../ManageGamer.module.css';

const MoveGamerToEnd = (props) => {
  const dispatch = useDispatch();
  const {gamerIds} = props;

  const moveGamerToEndHandler = () => {
    gamerIds.forEach(gamerId =>
      dispatch(moveGamerToEnd({
        gamerId: gamerId
      }))
    );
  };

  return (
    <Button className={classes.Button} onClick={moveGamerToEndHandler}>Move to end</Button>
  )
};

export default MoveGamerToEnd;