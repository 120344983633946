import React from 'react';
import classes from './GamerNotesIcon.module.css';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const GamerNotesIcon = () => {
  return (
    <div className={classes.GamerNotesIcon}>
      <Example />
    </div>
  )
};

function renderTooltip(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      Click to see notes
    </Tooltip>
  );
}

const Example = () => (
  <OverlayTrigger
    placement="right"
    delay={{ show: 100, hide: 200 }}
    overlay={renderTooltip}
  >
    <div>
      &#9432;
    </div>
  </OverlayTrigger>
);

export default GamerNotesIcon;