import React from 'react';
import classes from './Title.module.css'

const Title = () => {
  return (
    <div className={classes.Title}>
      GrayGaming
    </div>
  )
};

export default Title;