import React from 'react';

import GrayGamingLogo from '../../assets/images/graygaming-logo.png';
import classes from './Logo.module.css'

const Logo = (props) => (
  <div className={classes.Logo}>
    <img src={GrayGamingLogo} alt="GrayGaming" />
  </div>
);

export default Logo;