import React, {useContext, useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {updateGamerStatus, moveGamer} from "../../../../slices/queue";
import QueuedGamerPosition from "./QueuedGamerPosition/QueuedGamerPosition";
import GamerNotesIcon from './GamerNotesIcon/GamerNotesIcon'
import Gamer from '../../Gamer/Gamer'
import GamerStatus from './GamerStatus/GamerStatus';
import classes from './QueuedGamer.module.css'


const QueuedGamer = (props) => {
  const dispatch = useDispatch();
  if (!props.gamer) {
    return null;
  }

  let gamerStatus = null;
  let gamerNotesIcon = null;

  const dragStartHandler = (ev) => {
    ev.dataTransfer.setData("drag-item", props.gamer['id']);
  };

  const dragOverHandler = (ev) => {
    ev.preventDefault();
  };

  const dragDroppedHandler = (ev) => {
    const droppedItem = ev.dataTransfer.getData("drag-item");
    dispatch(moveGamer({gamerId: droppedItem, newPos: props.position}));
  };

  let propClassName = '';

  if (props.className) {
    propClassName = props.className;
  }

  if (props.admin) {
    console.log(props.gamer);
    gamerStatus = (
      <GamerStatus status={props.gamer['status']}/>
    )
    if (props.gamer['notes'] !== "") {
      gamerNotesIcon = (
        <GamerNotesIcon />
      )
    }
  }

  return (
    <div onClick={props.onClick} draggable={props.draggable} onDragStart={dragStartHandler} onDragOver={dragOverHandler} onDrop={dragDroppedHandler} className={[classes.QueuedGamer, propClassName].join('   ')}>
      <QueuedGamerPosition>{props.position + 1}</QueuedGamerPosition>
      <div className={classes.Gamer}>
        <Gamer>{props.gamer['display_name']}</Gamer>
      </div>
      <div className={classes.GamerStatuses}>
        {gamerNotesIcon}
        {gamerStatus}
      </div>
    </div>
  )
};

export default QueuedGamer;