import React from 'react';
import classes from './Gamer.module.css'

const gamer = (props) => {
    return (
      <div className={classes.Gamer}>
        <span className={classes.DisplayName}>{props.children}</span>
      </div>
    )
};

export default gamer;