import React, {useContext, useState, useRef, useEffect} from 'react'
import {AuthContext} from "../../../context/auth-context";
import {Button, Modal} from "react-bootstrap";
import classes from './Login.module.css';

const Login = props => {
  const authContext = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (show) {
      inputRef.current.select();
    }
  }, [show]);

  const handleShow = () => {
    setShow(true);
  };
  const handleHide = () => setShow(false);

  const loginHandler = () => {
    authContext.login()
  };

  const logoutHandler = () => {
    authContext.logout()
  };

  const clearLogin = () => {
    setShow(false);
    setUsername("");
    setPassword("");
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    tryLogin();
  };

  const tryLogin = () => {
    if (username === 'admin' && password === 'robisamazing') {
      clearLogin();
      loginHandler();
    } else {
      setError('Incorrect.');
      inputRef.current.select();
    }
  };

  let x = (
    <div>
      <Button className={classes.Button} onClick={handleShow}>Login</Button>
      <Modal show={show} onHide={handleHide}>
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span>Username:</span>
            </div>
            <div>
              <input className={classes.Input} ref={inputRef} type="text" onChange={(e) => setUsername(e.target.value)}/>
            </div>
            <div>
              <span>Password:</span>
            </div>
            <div>
              <input className={classes.Input} type="password" onChange={(e) => setPassword(e.target.value)}/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {error && <span className={classes.Error}>{error}</span>}
            <Button type="submit" onClick={tryLogin}>Login</Button>
            <Button onClick={handleHide}>Cancel</Button>
          </Modal.Footer>

        </form>
      </Modal>
    </div>
  );

  if (authContext.isAuth) {
    x = (
      <Button className={classes.Button} onClick={logoutHandler}>Logout</Button>
    );
  }

  return (
    x
  );
};

export default Login;