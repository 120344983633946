import React, {useState, useRef, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {setNotes} from "../../../../../slices/queue";
import {Button, Modal} from "react-bootstrap";

import classes from './SetGamerNotes.module.css';

const SetGamerNotes = props => {
  const {activeGamerDetails} = props;
  const dispatch = useDispatch();
  const {gamerId} = props;
  const [notesText, setNotesText] = useState('');
  const inputRef = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      inputRef.current.select();
    }
  }, [show]);

  const handleShow = () => {
    setNotesText(activeGamerDetails['notes']);
    setShow(true)
  };

  const handleHide = () => setShow(false);

  const submitNotes = () => {
    dispatch(setNotes({
      gamerId: activeGamerDetails['id'],
      notes: notesText,
    }));
    setNotesText('');
    handleHide();
  };

  const cancel = () => {
    handleHide();
  };

  return (
    <>
      <Button className={classes.Button} onClick={handleShow}>Set Notes</Button>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Set Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea ref={inputRef} onChange={e => setNotesText(e.target.value)}>{notesText}</textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={submitNotes}>Confirm</Button>
          <Button onClick={cancel}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SetGamerNotes;