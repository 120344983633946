import React from 'react';

import classes from './GamerStatus.module.css';

const GamerStatus = (props) => {

  if (!props.status) return null;

  return (
    <div className={classes.GamerStatus}>
      {props.status}
    </div>
  )
};

export default GamerStatus;