import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {clearQueue} from "../../../../../slices/queue";
import {Button, Modal} from 'react-bootstrap';
import classes from './ClearQueue.module.css';

const ClearQueue = props => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleHide = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearQueueHandler = () => {
    dispatch(clearQueue());

    handleHide();
  };


  return (
    <>
      <Button className={classes.Button} onClick={handleShow}>Clear Queue</Button>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Clear Queue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove all gamers from the queue?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={clearQueueHandler}>Yes, remove them all</Button>
          <Button onClick={handleHide}>No, keep them</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ClearQueue;