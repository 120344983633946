import React, {useState, useRef, useEffect} from 'react'
import {useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {queueSelector} from "../../../../../slices/queue";
import TextArea from './TextArea/TextArea';
import classes from './ViewAsText.module.css'

const ViewAsText = (props) => {
  const {queue, queuePlayed, gamerDetails} = useSelector(queueSelector);
  const [show, setShow] = useState(false);
  const [listAsText, setListAsText] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [useNumbers, setUseNumbers] = useState(true);
  const [useCommas, setUseCommas] = useState(false);
  const [useNewline, setUseNewlines] = useState(true);
  const textAreaRef = useRef(null);

  const [totalQueue, setTotalQueue] = useState([]);

  useEffect(() => {
    if (!show) {
      setCopySuccess("");
    }
  }, [show]);

  useEffect(() => {
    setTotalQueue([...queue, ...queuePlayed]);
  }, [queue, queuePlayed]);

  useEffect(() => {
    createListAsText();
  }, [useNumbers, useCommas, useNewline]);

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!')
  };

  const handleHide = () => setShow(false);
  const handleShow = () => {
    createListAsText();
    setShow(true);
  };

  const createListAsText = () => {
    let textList = "";
    let counter = 0;

    const addToTextList = (gamer) => {
      counter++;
      if (useNumbers) textList += counter.toString() + ". " ;

      textList += gamerDetails[gamer]['display_name'] ;

      if (useCommas) textList += ",";

      if (useNewline) {
        textList += "\n";
      } else {
        textList += " ";
      }
    };

    totalQueue.forEach(addToTextList);

    if (useCommas) {
      textList = textList.slice(0, -2)
    } else {
      textList = textList.slice(0, -1)
    }

    setListAsText(textList);
  };



  return (
    <>
      <Button className={classes.Button} onClick={handleShow}>View as text</Button>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header>
          <Modal.Title>List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextArea textAreaRef={textAreaRef} rows={totalQueue.length} listAsText={listAsText} />
          <div>
            <div>
              <input type="checkbox" checked={useNumbers} onChange={(e) => setUseNumbers(e.target.checked)}/>
              <span>Use Numbers</span>
            </div>
            <div>
              <input type="checkbox" checked={useCommas} onChange={(e) => setUseCommas(e.target.checked)}/>
              <span>Use Commas</span>
            </div>
            <div>
              <input type="checkbox" checked={useNewline} onChange={(e) => setUseNewlines(e.target.checked)}/>
              <span>Use Newlines</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {copySuccess}
          {
            document.queryCommandSupported('copy') &&
              <div className={classes.CopyDiv}>
                <Button onClick={copyToClipboard}>Copy to clipboard</Button>
              </div>
          }
          <Button onClick={handleHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ViewAsText;