import { createSlice } from '@reduxjs/toolkit'
import * as gamerStates from '../constants/gamerStates';

export const initialState = {
  queue: [],
  queuePlayed: [],
  gamerDetails: {},
  activeGamers: [],
};

const queueSlice = createSlice({
  name: 'queue',
  initialState,
  reducers: {
    fetchQueues: state => {

    },
    updateQueues: (state, { payload }) => {
      state.queue = payload['queue'];
      state.queuePlayed = payload['queuePlayed'];
      state.gamerDetails = payload['gamerDetails'];
    },
    setQueue: (state, { payload }) => {
      state.queue = payload.queue;
    },
    setQueuePlayed: (state, { payload }) => {
      state.queuePlayed = payload.queuePlayed;
    },
    setActiveGamer: (state, { payload }) => {
      state.activeGamers = [payload.gamerId];
    },
    addActiveGamer: (state, { payload }) => {
      let activeGamers = state.activeGamers.filter(x => x !== payload.gamerId);
      state.activeGamers = [...activeGamers, payload.gamerId]
    },
    shiftSelectGamers: (state, { payload }) => {
      let newActiveGamers = state.activeGamers.filter(x => x !== payload.gamerId);
      if (newActiveGamers.length === 0) {
        state.activeGamers = [payload.gamerId]
      } else if (state.activeGamers[-1] === payload.gamerId) {

      } else {
        const totalQueue = [...state.queue, ...state.queuePlayed];
        newActiveGamers = [];
        let fromGamer = state.activeGamers[state.activeGamers.length - 1];
        let toGamer = payload.gamerId;
        let fromGamerIndex = totalQueue.indexOf(fromGamer);
        let toGamerIndex = totalQueue.indexOf(toGamer);
        if (fromGamerIndex > toGamerIndex) {
          let temp = fromGamerIndex;
          fromGamerIndex = toGamerIndex;
          toGamerIndex = temp;
        }
        for (let i=fromGamerIndex; i<=toGamerIndex; i++) {
          newActiveGamers.push(totalQueue[i])
        }
        state.activeGamers = newActiveGamers;
      }
    },
    updateGamerStatus: (state, { payload }) => {
      const {gamerId, status} = payload;
      let gamer = {...state.gamerDetails[gamerId]};

      if (status === gamerStates.PLAYED && gamer.status !== gamerStates.PLAYED) {
        state.queue = state.queue.filter(x => x !== gamerId);
        state.queuePlayed = [...state.queuePlayed, gamerId];
      } else if (gamer.status === gamerStates.PLAYED && status !== gamerStates.PLAYED) {
        state.queuePlayed = state.queuePlayed.filter(x => x !== gamerId);
        state.queue = [...state.queue, gamerId];
      }

      gamer.status = status;
      state.gamerDetails = {...state.gamerDetails, [gamerId]: gamer};

      fetch('/v1/set_gamer_status', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          'unique_id': gamerId,
          'status': status,
        })
      })
    },
    addGamer: (state, { payload }) => {
      const { displayName } = payload;
      state.queue = [...state.queue, displayName];
      state.gamerDetails = {...state.gamerDetails, [displayName]: {id: displayName, display_name: displayName, metadata: {}, notes: '', status: null}};
      fetch('/v1/add_to_queue', {
        method: 'POST',
        body: JSON.stringify({
          "display_name": displayName,
          "unique_id": displayName,
        }),
        headers: { 'Content-Type': 'application/json' }
      });
    },
    deleteGamer: (state, { payload }) => {
      const { gamerId } = payload;
      fetch('/v1/delete_from_queue', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          unique_id: gamerId,
        })
      });

      state.queue = state.queue.filter(x => x !== gamerId);
      state.queuePlayed = state.queuePlayed.filter(x => x !== gamerId);
      state.activeGamers = [];

      let newGamerDetials = {...state.gamerDetails};
      delete newGamerDetials[gamerId];
      state.gamerDetails = newGamerDetials;
    },
    moveGamerToEnd: (state, { payload }) => {
      const { gamerId } = payload;
      if ( state.gamerDetails[gamerId]['status'] === gamerStates.PLAYED ) {
        let queuePlayed = state.queuePlayed.filter(x => x != gamerId);
        state.queuePlayed = [...queuePlayed, gamerId];
      } else {
        let queue = state.queue.filter(x => x !== gamerId);
        state.queue = [...queue, gamerId];
      }
      fetch('/v1/move_gamer_to_end', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          unique_id: gamerId,
        })
      });
    },
    moveGamer: (state, { payload }) => {
      let { gamerId, newPos } = payload;
      fetch('/v1/move_gamer', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          'unique_id': gamerId,
          'new_pos': newPos,
        })
      });
      if ( state.gamerDetails[gamerId]['status'] === gamerStates.PLAYED ) {
        let queuePlayed = state.queuePlayed.filter(x => x !== gamerId);
        newPos = Math.max(0, newPos - state.queue.length);
        queuePlayed.splice(newPos, 0, gamerId);
        state.queuePlayed = queuePlayed;
      } else {
        let queue = state.queue.filter(x => x !== gamerId);
        newPos = Math.min(newPos, queue.length);
        queue.splice(newPos, 0, gamerId);
        state.queue = queue;
      }
      state.activeGamers = [gamerId];

    },
    clearQueue: (state) => {
      fetch('/v1/clear_queue', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
      });
      state.queue = [];
      state.queuePlayed = [];
      state.gamerDetails = [];
      state.activeGamers = [];
    },
    setNotes: (state, {payload}) => {
      let { gamerId, notes } = payload;
      let gamer = {...state.gamerDetails[gamerId]};
      gamer['notes'] = notes;
      state.gamerDetails = {...state.gamerDetails, [gamerId]: gamer};

      fetch('/v1/set_gamer_notes', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          unique_id: gamerId,
          notes: notes,
        })
      });
    }
  },
});

export const {
  updateQueues,
  setQueue,
  setQueuePlayed,
  updateGamerStatus,
  addGamer,
  deleteGamer,
  moveGamerToEnd,
  addActiveGamer,
  setActiveGamer,
  shiftSelectGamers,
  clearQueue,
  moveGamer,
  setNotes,
} = queueSlice.actions;

// A selector
export const queueSelector = state => state.queue;

// The reducer
export default queueSlice.reducer

export const fetchQueues = () => {
  return dispatch => {
    fetch('/v1/queue')
    .then(response => {
      return response.json()
    })
    .then(data => {
      dispatch(updateQueues(data['data']));
    })
    .catch(error => {
      console.log(error);
    })
  }
};