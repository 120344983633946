import React from 'react';
import {useDispatch} from "react-redux";
import {deleteGamer} from "../../../../../slices/queue";
import {Button} from "react-bootstrap";

import classes from '../ManageGamer.module.css';

const DeleteGamer = (props) => {
  const dispatch = useDispatch();
  const {gamerIds} = props;

  const deleteHandler = () => {
    gamerIds.forEach(gamerId =>
      dispatch(deleteGamer({
        gamerId: gamerId
      }))
    );
  };

  return (
    <Button className={classes.Button} onClick={deleteHandler}>Delete</Button>
  )
};

export default DeleteGamer;