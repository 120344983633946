import React, {useContext} from 'react';
// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import GamingQueue from './components/GamingQueue/GamingQueue'
import Header from './components/Header/Header'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-169948652-1');
ReactGA.pageview(window.location.pathname + window.location.search);
// import LiveVideosList from './components/LiveVideosList/LiveVideosList'

const App = props => {

  return (
    <div className="App">
      <Header />
      {/*<LiveVideosList/>*/}
      <GamingQueue/>

    </div>
  );
};

export default App;
