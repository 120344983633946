import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {queueSelector, setActiveGamer, addActiveGamer, shiftSelectGamers} from "../../../../slices/queue";
import QueuedGamer from "../../../GamingQueue/QueuedGamers/QueuedGamer/QueuedGamer";
import SectionTitle from '../../../UI/SectionTitle/SectionTitle';

import classes from './QueuedGamers.module.css';

const QueuedGamers = (props) => {
  const dispatch = useDispatch();
  const {queue, queuePlayed, activeGamers, gamerDetails} = useSelector(queueSelector);
  const [totalQueue, setTotalQueue] = useState([]);

  useEffect(() => {
    setTotalQueue([...queue, ...queuePlayed])
  }, [queue, queuePlayed]);

  const activeGamerHandler = (e, gamerId) => {
    if (window.event.metaKey || e.ctrlKey) {
      dispatch(addActiveGamer({gamerId: gamerId}));
    } else if (e.shiftKey) {
      dispatch(shiftSelectGamers({gamerId: gamerId}))
    } else {
      dispatch(setActiveGamer({gamerId: gamerId}));
    }
  };

  return (
    <div className={classes.QueuedGamers}>
      <SectionTitle>Queued Gamers</SectionTitle>
      {totalQueue.map((gamer, index) =>
        <QueuedGamer
          admin={true}
          draggable={true}
          active={activeGamers.includes(gamer)}
          onClick={(e) => activeGamerHandler(e, gamer)}
          className={classes.QueuedGamer + " " + (activeGamers.includes(gamer) ? classes.QueuedGamerActive : "")}
          position={index}
          gamer={gamerDetails[gamer]}
        />)}
    </div>
  )
};

export default QueuedGamers;