import React from 'react';
import {useSelector} from "react-redux";
import {queueSelector} from "../../../../slices/queue";
import classes from './ManageGamer.module.css';
import SectionTitle from '../../../UI/SectionTitle/SectionTitle';
import SetGamerStatus from "./SetGamerStatus/SetGamerStatus";
import DeleteGamer from './DeleteGamer/DeleteGamer';
import MoveGamerToEnd from "./MoveGamerToEnd/MoveGamerToEnd";
import SetGamerNotes from './SetGamerNotes/SetGamerNotes';
import * as gamerStates from "../../../../constants/gamerStates";
import gamer from "../../Gamer/Gamer";

const ManageGamer = () => {
  const {activeGamers, gamerDetails} = useSelector(queueSelector);

  let managerGamerPanel = <div><span className={classes.DefaultMessage}>Please select an item from the queue.</span></div>;
  let gamerDetailsPanel = null;
  let notesPanel = null;

  if (activeGamers.length === 1) {
    const activeGamer = activeGamers[0];
    const activeGamerDetails = gamerDetails[activeGamer];
    if (gamerDetails[activeGamers]) {
      gamerDetailsPanel = (
        <>
          <div><span>Display name: {activeGamerDetails['display_name']}</span></div>
          <div><span>Status: {activeGamerDetails['status']}</span></div>
        </>
      )
    }
    let notes = "None.";
    if (activeGamerDetails && activeGamerDetails['notes'] !== "") {
      notes = activeGamerDetails['notes'];
    }
    notesPanel = (
      <div>
        <div className={classes.StatusButtonsTitle}>Notes</div>
        <SetGamerNotes activeGamerDetails={activeGamerDetails}/>
        <div>
          <p>
            {notes}
          </p>
        </div>
      </div>
    )
  } else if (activeGamers.length > 1) {
    gamerDetailsPanel = <span>{activeGamers.length} selected.</span>
  }

  if (activeGamers.length > 0) {
    managerGamerPanel = (
      <div className={classes.GamerDetailsContainer}>
        <div className={classes.GamerDetails}>
          {gamerDetailsPanel}
        </div>
        <div className={classes.GamerActions}>
          <div className={classes.StatusButtonsTitle}>Operations</div>
          <div>
            <MoveGamerToEnd gamerIds={activeGamers}/>
            <DeleteGamer gamerIds={activeGamers}/>
          </div>
          <div className={classes.StatusButtonsTitle}>Change Status</div>
          <div className={classes.StatusButtons}>
            <SetGamerStatus gamerIds={activeGamers} status={gamerStates.CLEAR} buttonText="Clear"/>
            <SetGamerStatus gamerIds={activeGamers} status={gamerStates.PLAYED} buttonText="PLAYED"/>
            <SetGamerStatus gamerIds={activeGamers} status={gamerStates.PLAYING} buttonText="PLAYING"/>
            <SetGamerStatus gamerIds={activeGamers} status={gamerStates.MISSED} buttonText="MISSED"/>
          </div>
          {notesPanel}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.ManageGamer}>
      <SectionTitle>Manage Gamer</SectionTitle>
      {managerGamerPanel}
    </div>
  )
};

export default ManageGamer;