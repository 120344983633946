import React, {useState, useEffect, useContext} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {AuthContext} from '../../context/auth-context'
import {useInterval} from '../../hooks/set-interval'
import {fetchQueues, queuesSelector, queueSelector, setQueue} from "../../slices/queue"
import QueuedGamer from './QueuedGamers/QueuedGamer/QueuedGamer'
import QueuedGamers from './QueuedGamers/QueuedGamers'
import AdminLayout from './AdminLayout/AdminLayout'

import classes from './GamingQueue.module.css'

const GamingQueue = () => {
  const dispatch = useDispatch();
  const {gamerDetails, queue, queuePlayed} = useSelector(queueSelector);
  const authContext = useContext(AuthContext);
  const [totalQueue, setTotalQueue] = useState([]);

  let layout = null;
  let queuedGamers = <div><span className={classes.DefaultMessage}>The queue is currently empty!</span></div>;

  useEffect(() => {
    dispatch(fetchQueues());
  }, [dispatch]);

  useEffect(() => {
    setTotalQueue([...queue, ...queuePlayed]);
  }, [queue, queuePlayed]);

  useInterval(() => {
    dispatch(fetchQueues());
  }, 1000);

  let playing = [];
  let missed = [];
  let played = [];
  let queued = [];

  let gamer = null;
  let gamerName = null;

  for (let i=0; i<totalQueue.length; i++) {
    gamerName = totalQueue[i];
    gamer = gamerDetails[gamerName];
    if (gamer) {
      if (gamer['status'] === "PLAYING") {
        playing.push(gamer)
      } else if (gamer['status'] === 'PLAYED') {
        played.push(gamer)
      } else if (gamer['status'] === 'MISSED') {
        missed.push(gamer)
      } else {
        queued.push(gamer)
      }
    }
  }

  let position = 0;
  let queuedGamersList = [];

  if (totalQueue && totalQueue.length > 0) {
    if (playing.length > 0) {
      queuedGamersList.push(<div className={classes.QueueHeader}><span>Playing</span></div>)
    }
    for (let i=0; i<playing.length; i++) {
      queuedGamersList.push(
        <QueuedGamer
          position={position}
          gamer={playing[i]}
        />);
      position += 1
    }

    if (queued.length > 0) {
      queuedGamersList.push(<div className={classes.QueueHeader}><span>Queued</span></div>)
    }

    for (let i=0; i<queued.length; i++) {
      queuedGamersList.push(
        <QueuedGamer
          position={position}
          gamer={queued[i]}
        />);
      position += 1
    }

    if (missed.length > 0) {
      queuedGamersList.push(<div className={classes.QueueHeader}><span>Missed</span></div>)
    }

    for (let i=0; i<missed.length; i++) {
      queuedGamersList.push(
        <QueuedGamer
          position={position}
          gamer={missed[i]}
        />);
      position += 1
    }

    if (played.length > 0) {
      queuedGamersList.push(<div className={classes.QueueHeader}><span>Played</span></div>)
    }

    for (let i=0; i<played.length; i++) {
      queuedGamersList.push(
        <QueuedGamer
          position={position}
          gamer={played[i]}
        />);
      position += 1
    }

    queuedGamers = (
      <QueuedGamers>
        {queuedGamersList}
      </QueuedGamers>
    )
  }

  if (authContext.isAuth) {
    layout = <AdminLayout queue={queue} queuePlayed={queuePlayed} gamerDetails={gamerDetails} setQueue={setQueue}/>
  } else {
    layout = (
      <div>
        {queuedGamers}
      </div>
    )
  }

  return (
    <div className={classes.GamingQueue}>
      {layout}
    </div>
  )
};

export default GamingQueue;