import React from 'react';
import QueuedGamers from './QueuedGamers/QueuedGamers'
import AdminActions from './AdminActions/AdminActions'
import ManageGamer from './ManageGamer/ManageGamer'
import classes from './AdminLayout.module.css';


const AdminLayout = (props) => {
  const {queue, setQueue, gamerDetails} = props;

  return (
    <div className={classes.AdminLayout}>
      <AdminActions queue={queue} setQueue={setQueue}/>
      <div className={classes.QueuePanels}>
        <QueuedGamers queue={queue} gamerDetails={gamerDetails}/>
        <ManageGamer/>
      </div>
    </div>
  )
};

export default AdminLayout;