import React from 'react';
import Navbar from './Navbar/Navbar'
import Title from './Title/Title'
import Login from './Login/Login'
import {Row} from "react-bootstrap";
import classes from './Header.module.css'
import Logo from '../Logo/Logo'

const Header = () => {
  return (
    <header className={classes.Header}>
      <div className={classes.Logo}>
        <Logo />
      </div>
      <Title/>
      <div className={classes.Login}>
        <Login/>
        {/*<Navbar />*/}
      </div>
    </header>
  )
};

export default Header;