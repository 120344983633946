import React from 'react';
import {useDispatch} from "react-redux";
import {updateGamerStatus} from "../../../../../slices/queue";
import {Button } from "react-bootstrap";

import classes from './SetGamerStatus.module.css';

const SetGamerStatus = props => {
  const dispatch = useDispatch();
  const {gamerIds} = props;

  let buttonText = `Set ${props.status}`;

  const submitStatus = () => {
    gamerIds.forEach(gamerId =>
      dispatch(updateGamerStatus({
        gamerId: gamerId,
        status: props.status,
      }))
    )
  };

  if (props.buttonText) {
    buttonText = props.buttonText;
  }

  return (
    <div className={classes.SetGamerStatusDiv}>
      <Button onClick={submitStatus}>{buttonText}</Button>
    </div>
  )
}

export default SetGamerStatus;